<template>
  <b-row>
    <b-col cols="12">
      <h2>Listar eventos</h2>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80" />

    <div class="col-12 mt-16">
      <datatable
        :items="items"
        :fields="fields"
        @changePerPage="perPageChanged"
      >
        <template slot="filters">
          <b-col
            cols="3"
            class="my-3"
          >
            <b-button
              v-b-modal.modal-add-sales-admin
              variant="primary"
              class="btn-ghost"
              @click="() => createSales = {}"
            >
              Añadir nuevo evento
            </b-button>
          </b-col>
        </template>

        <template
          slot="actions"
          slot-scope="{ item }"
        >
          <b-row class="actions-icons">
            <span
              title="ver"
              @click="showUser(item.uuid)"
            >
              <i class="iconly-Light-Show" />
            </span>
            <span
              title="editar"
              @click="editUser(item.uuid)"
            >
              <i class="iconly-Light-Edit mr-10" />
            </span>
            <span
              title="eliminar"
              @click="showDeleteConfirmation(item.uuid)"
            >
              <i class="iconly-Light-Delete mr-10" />
            </span>
          </b-row>
        </template>

        <template slot="pagination">
          <b-pagination
            align="end"
            :total-rows="totalRows"
            :per-page="totalRowsPerpage"
            @change="pageChanged"
          />
        </template>
      </datatable>
    </div>
    <!-- create sale -->
    <b-modal
      id="modal-add-sales-admin"
      size="xl"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-add-sales-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">
            Añadir nuevo evento
          </h5>

          <b-button
            variant="text"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
            @click="close()"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </div>

        <div class="divider my-0 p-0" />
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Título">
              <b-form-input
                v-model="createSales.title"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Descripción">
              <b-form-input
                v-model="createSales.description"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Fecha de inicio">
              <VDatePicker
                v-model="createSales.date_start"
                class="col-12"
                locale="es"
                transparent
                borderless
                mode="dateTime"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Fecha final">
              <VDatePicker
                v-model="createSales.date_end"
                class="col-12"
                locale="es"
                transparent
                borderless
                mode="dateTime"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Imagen">
              <b-form-file
                ref="file-input"
                placeholder="Seleccione una imagen"
                accept=".jpg, .png, .gif"
                @change="uploadFile"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button
              variant="primary"
              block
              @click="create_sale"
            >
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-add-sales-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- Modal de confirmación de eliminación -->
    <b-modal
      id="modal-delete-confirmation"
      title="eliminar"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-delete-confirmation"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">
            Confirmar eliminación
          </h5>

          <b-button
            variant="text"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
            @click="close()"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </div>

        <div class="divider my-0 p-0" />
      </template>

      <b-row>
        <b-col cols="12">
          <p class="text-center">
            ¿Estás seguro de que deseas eliminar este evento?
          </p>
        </b-col>

        <b-col
          cols="6"
          class="pr-2"
        >
          <b-button
            variant="danger"
            block
            @click="confirmDelete"
          >
            Sí, eliminar
          </b-button>
        </b-col>

        <b-col
          cols="6"
          class="pl-2"
        >
          <b-button
            variant="none"
            block
            @click="$bvModal.hide('modal-delete-confirmation')"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- edit sale -->
    <b-modal
      id="modal-edit-sales-admin"
      size="xl"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-edit-sales-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">
            Añadir nuevo evento
          </h5>

          <b-button
            variant="text"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
            @click="close()"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </div>

        <div class="divider my-0 p-0" />
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Título">
              <b-form-input
                v-model="createSales.title"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Descripción">
              <b-form-input
                v-model="createSales.description"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Fecha de inicio">
              <VDatePicker
                v-model="createSales.date_start"
                class="col-12"
                locale="es"
                transparent
                borderless
                mode="dateTime"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Fecha final">
              <VDatePicker
                v-model="createSales.date_end"
                class="col-12"
                locale="es"
                transparent
                borderless
                mode="dateTime"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Imagen">
              <b-form-file
                ref="file-input"
                placeholder="Seleccione una imagen"
                accept=".jpg, .png, .gif"
                @change="uploadFile"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button
              variant="primary"
              block
              @click="submitFileEdit"
            >
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-edit-sales-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- View sale detail -->
    <b-modal
      id="modal-show-admin"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">
            Ver detalle producto
          </h5>

          <b-button
            variant="text"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
            @click="close()"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </div>

        <div class="divider my-0 p-0" />
      </template>

      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Título">
              <b-form-input
                v-model="createSales.title"
                type="text"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-8"
          >
            <b-form-group label="Descripción">
              <b-form-input
                v-model="createSales.description"
                type="text"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-8"
          >
            <b-form-group label="Fecha de inicio">
              <b-form-input
                v-model="date_start"
                type="text"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-8"
          >
            <b-form-group label="Fecha final">
              <b-form-input
                v-model="date_end"
                type="text"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-8"
          >
            <b-form-group label="Imagen">
              <img :src="fileshow">
            </b-form-group>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-show-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormFile,
  BFormSelectOption,
  BFormDatepicker,
  BCalendar,
  BPagination,
} from 'bootstrap-vue'

import datatable from '@/layouts/components/datatable/Datatable.vue'
import sweet from '@/logic/functions/sweetAlert'
import Events from '@/logic/functions/events'
import { DateTime } from 'luxon'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect, // this
    BFormSelectOption,
    BFormDatepicker,
    BCalendar, // this
    BFormFile,
    BPagination,
    datatable,
  },
  data() {
    return {
      fields: [
        { key: 'title', label: 'Título' },
        { key: 'description', label: 'Descripción' },
        {
          key: 'date_start',
          label: 'Inicio',
          formatter: (value, key, item) => (
            `${DateTime.fromISO(item.date_start).toFormat('dd/MM/yyyy HH:mm ')
            }hrs`
          ),
        },
        {
          key: 'date_end',
          label: 'Final',
          formatter: (value, key, item) => (
            `${DateTime.fromISO(item.date_end).toFormat('dd/MM/yyyy HH:mm ')
            }hrs`
          ),
        },
        { key: 'actions', label: 'Acciones' },
      ],
      Actions: [],
      items: [],
      loadNewUsers: [],
      codeText: '',
      codeActive: false,
      codeActiveClass: false,
      flags: {
        formUpload: false,
        fileUpload: false,
      },
      fileUpload: {},
      createSales: {},
      fileshow: '',
      date_start: '',
      date_end: '',
      paginationData: {},
      totalRows: 0,
      totalRowsPerpage: 5,
      currentPage: 0,
    }
  },
  beforeMount() {},
  mounted() {
    this.list()
  },
  methods: {
    formValidate() {
      let stateForm = false
      if (
        this.createSales.title
        && this.createSales.description
        && this.createSales.date_start
        && this.createSales.date_end
        && this.fileUpload.file
      ) {
        stateForm = true
      }

      return stateForm
    },
    showUser(uuid) {
      this.createSales = this.items.find(item => item.uuid == uuid)

      this.fileshow = this.createSales.image.path
      this.date_start = `${DateTime.fromISO(this.createSales.date_start).toFormat(
        'dd/MM/yyyy HH:mm ',
      )}hrs`
      this.date_end = `${DateTime.fromISO(this.createSales.date_end).toFormat(
        'dd/MM/yyyy HH:mm ',
      )}hrs`
      this.$bvModal.show('modal-show-admin')
    },
    editUser(uuid) {
      this.createSales = this.items.find(item => item.uuid == uuid)

      this.$bvModal.show('modal-edit-sales-admin')
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deleteUser(this.itemToDelete)
        this.$bvModal.hide('modal-delete-confirmation')
      }
    },
    showDeleteConfirmation(uuid) {
      this.itemToDelete = uuid
      this.$bvModal.show('modal-delete-confirmation')
    },
    deleteUser(uuid) {
      const { items } = this
      Events.delete(uuid)
        .then(response => {
          const index = items.findIndex(item => item.uuid === uuid)
          if (~index) {
            items.splice(index, 1)
          }
          sweet.ToastMsg('', 'success', 'Evento eliminado')
        })
        .catch(error => {})
    },
    async create_sale() {
      if (this.formValidate()) {
        this.createSales.date_start = new Date(
          this.createSales.date_start,
        ).toISOString()
        this.createSales.date_end = new Date(
          this.createSales.date_end,
        ).toISOString()

        const query = `?title=${
          this.createSales.title
        }&description=${
          this.createSales.description
        }&date_start=${
          this.createSales.date_start
        }&date_end=${
          this.createSales.date_end
        }&comment=images`
        const file = new FormData()
        file.append('file', this.fileUpload.file)

        await Events.create(file, query)
          .then(response => {
            this.list()
            this.$bvModal.hide('modal-add-sales-admin')
            sweet.ToastMsg('', 'success', 'Evento creado')
          })
          .catch(error => {
            sweet.ToastMsg('', 'success', 'Evento no creado')
          })
      } else {
        sweet.ToastMsg('', 'error', 'Campos no llenados')
      }
    },
    async submitFileEdit() {
      this.createSales.date_start = new Date(
        this.createSales.date_start,
      ).toISOString()
      this.createSales.date_end = new Date(
        this.createSales.date_end,
      ).toISOString()

      const query = `?event_uuid=${
        this.createSales.uuid
      }&title=${
        this.createSales.title
      }&description=${
        this.createSales.description
      }&date_start=${
        this.createSales.date_start
      }&date_end=${
        this.createSales.date_end
      }&comment=images`

      const file = new FormData()
      file.append('file', this.fileUpload.file)

      await Events.update(file, this.createSales.uuid, query)
        .then(response => {
          this.$bvModal.hide('modal-edit-document-admin')
          sweet.ToastMsg('', 'success', 'Noticia editada')
        })
        .catch(error => {
          sweet.ToastMsg('', 'error', 'Noticia no editada')
        })
    },
    uploadFile(event) {
      console.log('🚀 ~ uploadFile ~ event:', event)
      this.fileUpload.file = event.target.files[0]
    },
    async list(page = 0, limit = 5) {
      await Events.list({
        params: {
          limit: this.totalRowsPerpage || limit,
          page: this.currentPage || page,
        },
      })
        .then(response => {
          this.items = response.data.data
          this.totalRows = response.data.items
          this.totalRowsPerpage = response.data.limit
        })
        .catch(error => {})
    },
    async pageChanged(page) {
      page = page == 1 ? 0 : page - 1
      this.currentPage = page
      this.list(page)
    },
    async perPageChanged(perPage) {
      this.totalRowsPerpage = perPage
      this.currentPage = 0
      this.list(0, perPage)
    },
  },
}
</script>
